<template>
  <div class="mx-auto relative max-w-screen-2xl">
    <TheHeader />
    <div class="w-full flex flex-col items-center">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
@use "./assets/scss/main" as *;
</style>
