<script setup lang="ts">
const isBarVisible = ref(false);

function handleChangeBarVisibility() {
  isBarVisible.value = !isBarVisible.value;
}
</script>

<template>
  <header class="absolute top-0 left-0 w-full h-16 flex justify items-center pt-8 pr-8 z-50">
    <div class="z-0 top-8 left-0 flex justify-center items-center w-full">
      <Logo size="lg" />
      <button
        class="absolute lg:right-20 top-5 right-5 border-0 bg-transparent text-white text-4xl transition duration-300 cursor-pointer z-1 scale-x-150"
        @click="handleChangeBarVisibility()"
      >
        <Icon name="mdi:menu" size="34" color="#ffffff" />
      </button>
    </div>
  </header>
  <TheMenu v-if="isBarVisible" :handle-change-bar-visibility="handleChangeBarVisibility" />
</template>
