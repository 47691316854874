<script setup lang="ts">
interface Props {
  handleChangeBarVisibility: Function;
}

const menuLinks = [
  {
    text: "Featuring",
    to: "/featuring",
  },
  {
    text: "Serviços",
    to: "/services",
  },
  {
    text: "Editorial",
    to: "/editorial",
  },
  {
    text: "Contactos",
    to: "/contacts",
  },
];

defineProps<Props>();
</script>

<template>
  <div
    class="w-full fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 z-50 h-screen flex flex-col bg-dark items-center justify-center"
  >
    <Logo size="lg" class="absolute top-8" />
    <button
      class="text-white absolute right-20 top-8 self-start text-4xl bg-none border-none cursor-pointer scale-x-150 bg-transparent outline-none"
      @click="handleChangeBarVisibility()"
    >
      <Icon name="material-symbols:close-rounded" />
    </button>

    <nav class="flex w-full items-center justify-center">
      <ul class="w-full flex items-center justify-center flex-col list-none">
        <MenuListItem
          v-for="item in menuLinks"
          :to="item.to"
          :text="item.text"
          @click="handleChangeBarVisibility()"
        />
      </ul>
    </nav>
  </div>
</template>
